export interface BlockquoteProps {
  text: string
  caption?: string
}

export const Blockquote = ({ text, caption }: BlockquoteProps) => {
  return caption ? (
    <figure className="my-6 border-l-4" data-testid="blockquote">
      <blockquote className="max-w-screen-lg py-2 pl-4 font-serif text-xl italic lg:text-2xl lg:leading-9">
        {text}
      </blockquote>
      <figcaption className="py-1 pl-4">{caption}</figcaption>
    </figure>
  ) : (
    <blockquote className="my-6 max-w-screen-lg border-l-4 py-2 pl-4 font-serif text-xl italic lg:text-2xl lg:leading-9">
      {text}
    </blockquote>
  )
}

export type BlockquoteComponent = typeof Blockquote
